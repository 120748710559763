.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
  color: black;
}

a:visited {
  color: black;
}

li {
  list-style: none;
}

.cursor {
  cursor: pointer;
}

.lightGray {
  color: #4444 !important;
}

.minusMarginL4{
  margin-left: -4% !important;
}

.minusMarginR4{
  margin-right: -4% !important;
}

.marginAuto {
  margin: auto !important;
}

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .container {
    margin-top: 20%;
  }
}


@media (max-width: 500px) {
  .container {
    margin-top: 55%;
  }
}

.contents {
  width: 100vw;
  height: 100vh;
  cursor: pointer;
}

.nav {
  padding-top: 2%;
  padding-bottom: 2%;
  width: 100vw;
  height: 5vh;
  top: 0;
  position: fixed;
}

.nav .subject {
  margin-right: 5%;
  font-size: 12px;
}

.title {
  margin-left: 10%;
  margin-right: 30%;
  font-size: 15px;
}

.workContainer {
  height: 100%;
  margin-top: 10%;
}

.workContents {
  width: 30%;
  height: 15%;
  float: left;
  padding: 1%;
  margin: 0%;
}

.images {
  width: 100%;
  height: 180px;
  margin: 0;
}

.newsImages {
  width: 100%;
  height: auto;
  margin: 0;
}

.aboutContainer {
  height: 100%;
  margin-top: 10%;
}

.aboutTextArea {
  white-space: pre-line;
  font-size: 12px;
  line-height: 30px;
}

.aboutImageArea {
  height: auto;
}

.aboutImage {
  width: 90%;
}

.newsContainer {
  height: 100%;
  margin-top: 10%;
}

.backgroundColorWhite {
  background-color: white;
}

.btnStyle1 {
  float: right;
  font-size: 10px !important;
  text-transform: none !important;
  border: none !important;
}

@media (max-width: 500px) {
  .sxCarouselImg {
    width: 100% !important;
    height: 250px !important;
  }
}

.descriptionContainer{
  height: 80%;
  margin-top: 3%;
  white-space: pre-line;
  column-count: 3;
  column-fill: auto;
  overflow: auto;
  line-height: 30px;
}

@media (max-width: 500px) {
  .descriptionContainer{
    column-count: 1;
  }
}

.archiveContainer{
  height: 70%;
  margin-top: 10%;
  white-space: pre-line;
  column-count: 3;
  column-fill: auto;
  overflow: auto;
  line-height: 50px;
}

@media (max-width: 500px) {
  .archiveContainer {
    height: 80%;
    column-count: auto;
  }
}

.arcYear {
  color: gray;
}

.arcTitle {
  margin-left: 5%;
}

.footer {
  width: 100vw;
  height: 5vh;
  text-align: center;
}

.footerContent {
  color: gray;
}

@media (max-width: 500px) {
  .newsNav {
    margin-top: 5%;
  }
}